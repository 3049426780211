<template>
  <div class="container">
    <h2 class="text-center" v-if="question.question">{{ question.question }}</h2>
    <div class="options" v-if="question.question">
      <v-btn block :color="getColor(question.option1)" @click="submitAnswer(question.option1)" class="optionButton">{{ question.option1 }}</v-btn>
      <v-btn block :color="getColor(question.option2)" @click="submitAnswer(question.option2)" class="optionButton">{{ question.option2 }}</v-btn>
      <v-btn block :color="getColor(question.option3)" @click="submitAnswer(question.option3)" class="optionButton">{{ question.option3 }}</v-btn>
      <v-btn block :color="getColor(question.option4)" @click="submitAnswer(question.option4)" class="optionButton">{{ question.option4 }}</v-btn>
    </div>
    <p class="text-center" v-if="question.question">连续答对{{ correctCount }}题<a @click="restart" v-if="stop" class="ml-1">重新开始</a></p>
  </div>
</template>

<script>
import service from '@/utils/request';

export default {
  name: "Answer",
  data() {
    return {
      stop: false,
      waiting: true,
      question: {},
      correctOption: null,
      selectedOption: null,
      correctCount: 0
    }
  },
  props: {
    bankId: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchQuestion();
  },
  methods: {
    fetchQuestion() {
      service.get('/wikiapi/question', {
        params: {
          bankId: this.bankId
        }
      })
      .then(res => {
        this.question = res.data;
        this.correctOption = null;
        this.selectedOption = null;
        this.waiting = false;
      })
      .catch(err => {
        console.error(err);
      });
    },
    submitAnswer(myAnswer) {
      if (this.stop || this.waiting) {
        return;
      }
      this.waiting = true;
      
      service.post('/wikiapi/answer', null, {
        params: {
          questionId: this.question.id,
          myAnswer: myAnswer
        }
      })
      .then(res => {
        if (res.data.id === -1) {
          this.selectedOption = myAnswer;
          this.correctOption = res.data.correctAnswer;
          this.stop = true;
        } else {
          this.correctOption = myAnswer;

          setTimeout(() => {
            this.correctCount++;
            this.question = res.data;
            this.correctOption = null;
            this.selectedOption = null;
            this.waiting = false;
          }, 1000);
        }
      })
      .catch(err => {
        console.error(err);
      });
    },
    getColor(option) {
      if (this.correctOption === option) {
        return 'green';
      } else if (this.selectedOption === option) {
        return 'red';
      } else {
        return '';
      }
    },
    restart() {
      this.stop = false;
      this.correctCount = 0;
      this.fetchQuestion();
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.optionButton {
  width: 200px;
  margin: 20px 0;
}
</style>